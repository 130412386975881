exports.components = {
  "component---src-components-pages-generator-tsx": () => import("./../../../src/components/pages/Generator.tsx" /* webpackChunkName: "component---src-components-pages-generator-tsx" */),
  "component---src-components-pages-guide-tsx-content-file-path-src-mdx-guides-chicago-mdx": () => import("./../../../src/components/pages/Guide.tsx?__contentFilePath=/opt/buildhome/repo/src/mdx/guides/chicago.mdx" /* webpackChunkName: "component---src-components-pages-guide-tsx-content-file-path-src-mdx-guides-chicago-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-citations-list-tsx": () => import("./../../../src/pages/citationsList.tsx" /* webpackChunkName: "component---src-pages-citations-list-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-examples-tsx": () => import("./../../../src/pages/examples.tsx" /* webpackChunkName: "component---src-pages-examples-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-references-manager-tsx": () => import("./../../../src/pages/referencesManager.tsx" /* webpackChunkName: "component---src-pages-references-manager-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

