module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-fc5c453c54/0/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-00c76451d0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"citation-generator","short_name":"cg","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1037a3728f83c220d3b5a7fccf921dbb"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-66135fe357/0/cache/gatsby-plugin-google-gtag-npm-5.13.1-993d935611-a720c18461.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-BE1XNFX581"],"gtagConfig":{}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-adb4d222f0/0/cache/gatsby-plugin-google-tagmanager-npm-5.13.1-f82e0c3055-70454f6aa8.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NXVVV9H2","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-gatsby-cloud-virtual-00f5cfc2f4/0/cache/gatsby-plugin-gatsby-cloud-npm-5.13.1-3d3464d8f5-1a459e6cd4.zip/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-df3c998a6e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
